<template>
  <b-row
    class="vh-100 px-0 bg-success"
  >
    <div class="logo">
      <div class="h-100 d-flex justify-content-center align-items-center bg-success">
        <div class="d-flex justify-content-center align-items-center flex-column">
          <strong class="text-center">
            <h1 class="ifas-text-logo">INTEGRATED FINANCE ACCOUNTING SYSTEM</h1>
          </strong>
        </div>
      </div>
    </div>
    <div class="sign-in bg-white">
      <b-container
        fluid
        class="d-flex justify-content-center align-items-center flex-column h-100"
      >
        <b-row
          class="box-rounded"
        >
          <b-col cols="1" />
          <b-col
            cols="10"
          >
            <ValidationObserver
              ref="signinForm"
              v-slot="{ handleSubmit }"
            >
              <form
                role="form"
                @submit.prevent="handleSubmit(onSignIn)"
              >
                <b-row class="py-1">

                  <b-col
                    cols="12"
                    class="d-flex justify-content-between flex-row welcome-back"
                  >
                    <div class="flex-grow-1 order-1 align-self-center">
                      <h5 class="d-block border-bottom">
                        <strong>Welcome!</strong>
                      </h5>
                      <h6 class="d-block mt-0">
                        <strong>Sign In</strong>
                      </h6>
                    </div>
                    <div class="order-2 d-flex justify-content-center">
                      <div
                        class="py-3 border"
                        style="border-radius: 50px;"
                      >
                        <b-img
                          alt="Aljay Logo"
                          height="50"
                          width="90"
                          :src="companyLogo"
                        />
                      </div>
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="username"
                      name="username"
                      rules="required"
                    >
                      <b-form-group
                        label="Username"
                        label-for="username"
                        label-class="font-weight-bold"
                        class="mb-2"
                      >
                        <b-input
                          id="username"
                          v-model="credentials.username"
                          type="text"
                          placeholder="enter username here"
                          autocomplete="off"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="password"
                      name="password"
                      rules="required|min:8"
                    >
                      <b-form-group
                        label="Password"
                        label-for="password"
                        label-class="font-weight-bold"
                        class="mb-2"
                      >
                        <b-input-group
                          :class="errors.length > 0 ? 'is-invalid' : ''"
                        >
                          <b-input
                            id="password"
                            v-model="credentials.password"
                            placeholder="enter password here"
                            autocomplete="off"
                            :type="passwordType"
                            :state="errors.length > 0 ? false : null"
                            :disabled="state.busy"
                          />
                          <b-input-group-append>
                            <b-button
                              class="pl-2 pr-1"
                              variant="success"
                              style="border-radius: 0px 5px 5px 0px"
                              :disabled="state.busy"
                              @click="togglePassword"
                            >
                              <i
                                v-if="state.visible_password"
                                class="ri-eye-line h5"
                              />
                              <i
                                v-else
                                class="ri-eye-off-line h5"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-overlay
                      rounded
                      opacity="0.1"
                      spinner-variant="warning"
                      spinner-small
                      :show="state.local"
                    >
                      <b-button
                        class="col-12"
                        variant="success"
                        type="submit"
                        :disabled="state.busy"
                      >
                        Sign In
                      </b-button>
                    </b-overlay>
                  </b-col>

                  <b-col class="text-divider">
                    <span>or</span>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-overlay
                      rounded
                      opacity="0.1"
                      spinner-variant="success"
                      spinner-small
                      :show="state.sso"
                    >
                      <b-button
                        class="col-12"
                        type="button"
                        variant="warning"
                        :disabled="state.busy"
                        style="text-transform: none;"
                        @click="onSignInWithAljaySSO"
                      >
                        Sign in with Aljay SSO
                      </b-button>
                    </b-overlay>
                  </b-col>

                </b-row>
              </form>
            </ValidationObserver>
          </b-col>
          <b-col cols="1" />
        </b-row>
      </b-container>
    </div>
  </b-row>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SharedAuthService, SharedSSOService } from '@/services'
import { Oauth2 } from 'universal-social-auth'
import url from '@/mixins/url'
import logos from '@/mixins/logos'

export default {
  name: 'SharedLogin',

  middleware: ['guest'],

  metaInfo: () => ({
    title: 'Sign In'
  }),

  mixins: [
    url,
    logos
  ],

  data: () => ({
    state: {
      busy: false,
      local: false,
      sso: false,
      visible_password: false
    },
    credentials: {
      username: null,
      password: null
    },
    Oauth2
  }),

  computed: {

    companyLogo () {
      return this.transformToImageUrl(this.getCompany())
    },

    passwordType () {
      return this.state.visible_password ? 'text' : 'password'
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    togglePassword () {
      this.state.visible_password = !this.state.visible_password
    },

    async saveAndFetchUser (token) {
      this.$store.dispatch('auth/saveToken', token)
      return new Promise((resolve, reject) => {
        SharedAuthService.FetchUser().then(
          ({ data }) => {
            this.$store.dispatch('auth/fetchUserSuccess', {
              id: data.id,
              email: data.email,
              role: data.role.code,
              role_name: data.role.name,
              username: data.role.username,
              name: data.name
            })
            const user = this.$store.getters['auth/user']
            this.$router.replace({
              name: user ? `${user.role}.dashboard` : 'dashboard'
            }, () => {
              resolve(data)
            })
          }
        ).catch(error => {
          this.$store.dispatch('auth/fetchUserFailure')
          reject(error)
        })
      })
    },

    async onSignIn () {
      await this.$refs.signinForm.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = this.state.local = true
            SharedAuthService.SignIn(this.credentials).then(
              ({ data: { token } }) => {
                this.saveAndFetchUser(token).finally(() => {
                  this.state.busy = this.state.local = false
                })
              }
            ).catch(() => {
              this.state.busy = this.state.local = false
            })
          }
        }
      )
    },

    async onSignInWithAljaySSO (event) {
      event.preventDefault()
      this.state.busy = this.state.sso = true
      this.$Oauth.authenticate('oauth2', this.Oauth2).then(
        authorized => {
          SharedSSOService.post(authorized).then(
            ({ data: { token } }) => {
              this.saveAndFetchUser(token).finally(() => {
                this.state.busy = this.state.local = false
              })
            }
          ).catch(() => {
            this.state.busy = this.state.sso = false
          })
        }
      )
    }
  }
}
</script>

<style scoped>

.text-divider {
  margin: 1.5em 0;
  line-height: 0;
  text-align: center;
  color: #aab840;
}
.text-divider span {
  background-color: white;
  padding: .5em;
}
.text-divider:before {
  content: " ";
  display: block;
  border-top: 1px solid #c7d467;
  border-bottom: 1px solid #f7f7f7;
}
/* .font-cinzel-decorative {
  font-family: ;
} */
@font-face {
    font-family: 'Lato';
    src: url('/assets/lato/Lato-Black.ttf');
}
.ifas-text-logo {
  font-size: 30px;
  color: white;
  font-family: 'Lato';
  padding: 0px 10px;
}
.logo {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  height: 35vh;
}
.sign-in {
  border-radius: 70px 0px 0px 0px;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  height: 65vh;
}

@media screen and (orientation: landscape) {
  .logo {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    height: 100vh;
  }

  .sign-in {
    border-radius: 0px 0px 0px 0px;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    height: 100vh;
  }
}

@media screen and (orientation: landscape) and (max-width: 1024px) {
  .sign-in {
    border-radius: 0px 0px 0px 0px;
    /* .welcome-back {
      display: none !important;
    } */
  }
}

@media screen and (min-width: 1024px) {

  .logo {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    height: 100vh;
  }

  .sign-in {
    border-radius: 0px 0px 0px 0px;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    height: 100vh;
  }

}
</style>
